import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

export const MobileHeaderContainer = styled('div')`
  padding: ${themeGet('spacing.1')}px ${themeGet('spacing.2')}px;
  background-color: ${themeGet('colors.primary.1')};
  height: 50px;
  display: flex;
  justify-content: space-between;
`;

export const CompanyLogo = styled('img')`
  height: 100%;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName } from 'recompose';

import * as selectors from '../../store/selectors';
import SignUpButton from './sign-up-button';
import SignInButton from './sign-in-button';

export default compose(
  setPropTypes({
    signIn: PropTypes.string,
    signUp: PropTypes.string,
  }),
  connect(selectors.ssoLinkSelector),
  setDisplayName('SignInButtons')
)(({ signIn, signUp }) => (
  <React.Fragment>
    {signIn && <SignInButton href={signIn}/>}
    {signUp && <SignUpButton href={signUp}/>}
  </React.Fragment>
));

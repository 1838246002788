import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

import { currentUserSelector } from '../../store/selectors';
import SignInButtons from '../sign-in-buttons/index';
import SignOutButtons from '../sign-out-buttons/index';

const SessionButtonsContainer = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  padding-right: ${themeGet('spacing.5')}px;
  padding-top: ${themeGet('spacing.4')}px;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    padding-right: ${themeGet('spacing.2')}px;
    padding-top: ${themeGet('spacing.2')}px;
    font-size: ${themeGet('fonts.size.2')}px;
  }
`;

export default compose(
  connect(currentUserSelector),
  setPropTypes({ currentUser: PropTypes.object }),
  setDisplayName('SessionButtons')
)(({ currentUser }) => (
  <SessionButtonsContainer>
    {currentUser ? <SignOutButtons/> : <SignInButtons/>}
  </SessionButtonsContainer>
));

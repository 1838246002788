import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName, withHandlers, lifecycle, withState, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import Input from '@influitive/secret-garden/lib/form-inputs/input';
import MediaQuery from 'react-responsive';

import Button from '../button';
import Footer from '../footer/index';
import { qaSelector } from '../../utils';
import * as selectors from '../../store/selectors';
import { loadCurrentUser, loadHubs, createSession } from '../../store/actions';

const SignInContainer = styled('div')`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled('form')`
  max-width: 400px;
  width 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormControl = styled(Input)`
  padding-left: ${themeGet('spacing.2')}px;
  padding-right: ${themeGet('spacing.2')}px;
  padding-top: ${themeGet('spacing.4')}px;
  #email, #name, #password {
    height: ${themeGet('spacing.6')}px;
  }
  
  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    #email, #name, #password {
      font-size: ${themeGet('fonts.size.3')}px;
    }
  }
`;

const SubmitButtonContainer = styled('div')`
  max-width: 400px;
  width 100%;
  padding-top: ${themeGet('spacing.4')}px;
  padding-bottom ${themeGet('spacing.3')}px;
  padding-left ${themeGet('spacing.2')}px;
  padding-right ${themeGet('spacing.2')}px;
`;

const Logo = styled('img')`
  width: 80%;
  height: auto;
  padding-top: ${themeGet('spacing.2')}px;
  padding-bottom: ${themeGet('spacing.2')}px;
`;

const SingUpLinkContainer = styled('div')`
  max-width: 400px;
  width 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignUpLink = styled('a')`
  color: ${themeGet('colors.neutral.2')};
  padding-left: ${themeGet('spacing.1')}px;
`;

const ErrorContainer = styled('div')`
  max-width: 400px;
  width: 100%;
  color: ${themeGet('colors.error.0')};
  padding: ${themeGet('spacing.2')}px;
`;

const SessionFooter = styled(Footer)`
  height: 10%;
`;

export default compose(
  connect(
    state => ({
      ...selectors.gatewayDescriptionSelector(state),
      ...selectors.sessionSelector(state),
      ...selectors.currentUserSelector(state)
    }), { loadCurrentUser, loadHubs, createSession }
  ),
  withState('emailFilled', 'setEmailFilled', false),
  withState('passwordFilled', 'setPasswordFilled', false),
  lifecycle({
    componentDidUpdate() {
      if (this.props.currentUser) {
        this.props.history.push('/');
      }

      if (!this.props.sessionCreated) {
        return;
      }

      this.props.loadCurrentUser();
      this.props.loadHubs();
      this.props.history.push('/');
    }
  }),
  withHandlers({
    handleSubmit: ({ createSession }) => event => {
      event.preventDefault();
      const sessionFormData = new FormData(event.currentTarget);
      createSession(sessionFormData);
    },
    handleEmailValidation: ({ setEmailFilled }) => event => {
      setEmailFilled(event.currentTarget.value.length > 0 || false);
    },
    handlePasswordValidation: ({ setPasswordFilled }) => event => {
      setPasswordFilled(event.currentTarget.value.length > 0 || false);
    }
  }),
  setPropTypes({
    handleSubmit: PropTypes.func.isRequired,
    sessionCreateError: PropTypes.bool,
    sessionCreated: PropTypes.bool,
    attachmentLinks: PropTypes.object,
    loadingCompleted: PropTypes.bool,
    currentUser: PropTypes.object
  }),
  branch(
    ({ loadingCompleted }) => !loadingCompleted,
    renderNothing
  ),
  setDisplayName('NewSession')
)(({
  handleSubmit,
  sessionCreateError,
  attachmentLinks,
  handleEmailValidation,
  handlePasswordValidation,
  emailFilled,
  passwordFilled
}) => (
  <div id="ADVOCATE-GATEWAY-FE-SIGN-IN">
    <SignInContainer className="c-sign-in-container">
      <FormContainer className="c-form-container" onSubmit={handleSubmit} >
        <Logo className="c-logo" src={attachmentLinks.logo} />
        <FormControl
          className="c-form-control"
          label="Email"
          type="email"
          id="email"
          name="email"
          onChange={handleEmailValidation}
          data-qa={qaSelector('sign-in-form-email')} />
        <FormControl
          className="c-form-control"
          label="Password"
          type="password"
          id="password"
          name="password"
          onChange={handlePasswordValidation}
          data-qa={qaSelector('sign-in-form-password')} />
        {
          sessionCreateError && <ErrorContainer className="c-error-container">
            The email or password you've provided is incorrect.
          </ErrorContainer>
        }
        <SubmitButtonContainer className="c-submit-button-container">
          <Button className="c-submit-button" type="submit" variant="authorization" disabled={!emailFilled || !passwordFilled}>
            Sign In
          </Button>
        </SubmitButtonContainer>
        <SingUpLinkContainer className="c-sign-up-link-container">
          Need an account? <SignUpLink className="c-sign-up-link" href="/sign_up">Sign Up</SignUpLink>
        </SingUpLinkContainer>
      </FormContainer>
      <MediaQuery minWidth={576}>
        <SessionFooter className="c-session-footer"/>
      </MediaQuery>
    </SignInContainer>
  </div>
));

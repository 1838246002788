import { createAction } from 'redux-act';

import { getAuthenticationPayload } from '../../api/authentication-payload';

export const fetchingAuthenticationPayload = createAction('FETCHING_AUTHENTICATION_PAYLOAD');
export const authenticationPayloadLoadedSuccessfully = createAction('AUTHENTICATION_PAYLOAD_LOADED_SUCCESSFULLY');
export const authenticationPayloadLoadingFail = createAction('AUTHENTICATION_PAYLOAD_LOADING_FAIL');

export function loadAuthenticationPayload(hubId) {
  return dispatch => {
    dispatch(fetchingAuthenticationPayload());

    getAuthenticationPayload(hubId)
      .then(response => dispatch(authenticationPayloadLoadedSuccessfully(response.data)))
      .catch(() => dispatch(authenticationPayloadLoadingFail()));
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';

import Button from '../button';
import { qaSelector } from '../../utils';

export default compose(
  setPropTypes({ href: PropTypes.string }),
  setDisplayName('SignInButton')
)(({ href }) => (
  <Button className="c-sign-in-button" href={href} data-qa={qaSelector('sign-in-button')} variant={['primary', 'session']} title="Sign In">
    Sign In
  </Button>
));

import { createAction } from 'redux-act';

import { getCurrentUser } from '../../api/current-user';

export const fetchingCurrentUser = createAction('FETCHING_CURRENT_USER');
export const currentUserLoadedSuccessfully = createAction('CURRENT_USER_LOADED_SUCCESSFULLY');
export const currentUserLoadingFail = createAction('CURRENT_USER_LOADING_FAIL');
export const currentUserLoggedOut = createAction('CURRENT_USER_LOGGED_OUT');

export function loadCurrentUser() {
  return dispatch => {
    dispatch(fetchingCurrentUser());

    getCurrentUser()
      .then(response => dispatch(currentUserLoadedSuccessfully(response.data)))
      .catch(dispatch(currentUserLoadingFail()));
  };
}

import { createReducer } from 'redux-act';
import { fetchingSsoLink, ssoLinkLoadedSuccessfully, ssoLinkLoadingFail } from './actions';

const defaultState = { sign_in: '/sign_in', sign_up: '/sign_up', loadingCompleted: false };

const ssoLinkReducer = createReducer({
  [fetchingSsoLink]: state => ({ ...state, loadingCompleted: false }),
  [ssoLinkLoadedSuccessfully]: (state, payload) => ({ ...state, ...payload, loadingCompleted: true }),
  [ssoLinkLoadingFail]: state => ({ ...state, loadingCompleted: false })
}, defaultState);

export default ssoLinkReducer;

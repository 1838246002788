import React from 'react';

import GatewayDescription from '../gateway-description/index';
import HubList from '../hub-list/index';
import Footer from '../footer/index';
import { isMobileGatewayApp } from '../../utils/index';
import MobileGatewayApp from '../mobile-gateway-app/index';

export default () => (
  <div id="ADVOCATE-GATEWAY-FE">
    {!isMobileGatewayApp() ?
      <React.Fragment>
        <GatewayDescription />
        <HubList />
        <Footer />
      </React.Fragment> :
      <MobileGatewayApp />
    }
  </div>
);

import { combineReducers } from 'redux';

import currentUserReducer from './current-user/reducer';
import ssoLinkReducer from './sso-link/reducer';
import familyBrandingReducer from './family-branding/reducer';
import hubsReducer from './hubs/reducer';
import authenticationPayloadReducer from './authentication-payload/reducer';
import sessionReducer from './session/reducer';
import signUpReducer from './sign-up/reducer';
import joinRequestReducer from './join-request/reducer';

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  ssoLink: ssoLinkReducer,
  familyBranding: familyBrandingReducer,
  authenticationPayload: authenticationPayloadReducer,
  hubs: hubsReducer,
  session: sessionReducer,
  signUp: signUpReducer,
  joinRequests: joinRequestReducer
});

export default rootReducer;

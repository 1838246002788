import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName } from 'recompose';

import * as selectors from '../../store/selectors';

import MobileLogin from './mobile-app-login';
import MobileAppHubList from './mobile-app-hub-list';

export default compose(
  connect(
    state => ({
      ...selectors.currentUserSelector(state)
    })
  ),
  setPropTypes({
    currentUser: PropTypes.object
  }),
  setDisplayName('MobileGatewayApp')
)(({ currentUser }) => (
  <React.Fragment>
    {currentUser ? <MobileAppHubList/> : <MobileLogin/>}
  </React.Fragment>
));

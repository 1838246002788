import { createAction } from 'redux-act';

import { currentUserLoggedOut } from '../current-user/actions';
import { loadHubs } from '../hubs/actions';
import { createUserSession, destroyUserSession } from '../../api/session';

export const sessionCreateRequest = createAction('SESSION_CREATE_REQUEST');
export const sessionCreateSuccess = createAction('SESSION_CREATE_SUCCESS');
export const sessionCreateFailure = createAction('SESSION_CREATE_FAILURE');

export const sessionDestroyRequest = createAction('SESSION_DESTROY_REQUEST');
export const sessionDestroySuccess = createAction('SESSION_DESTROY_SUCCESS');
export const sessionDestroyFailure = createAction('SESSION_DESTROY_FAILURE');

export function createSession(formData) {
  return dispatch => {
    dispatch(sessionCreateRequest());
    createUserSession(formData)
      .then(() => dispatch(sessionCreateSuccess()))
      .catch(() => dispatch(sessionCreateFailure()));
  };
}

export function destroySession() {
  return dispatch => {
    dispatch(sessionDestroyRequest());

    destroyUserSession()
      .then(() => {
        dispatch(sessionDestroySuccess());
        dispatch(currentUserLoggedOut());
        dispatch(loadHubs());
      })
      .catch(() => dispatch(sessionDestroyFailure()));
  };
}

const emailValidationMessage = (email) => {
  let errors = [
    { check: /\S/i, message: 'must be filled' },
    { check: /[\w+\-.']+@[a-z\d\-.]+\.[a-z]/i, message: 'Please enter a valid email address' }
  ].map((validation) => {
    if (!validation.check.test(email)) return validation.message;
  }).filter(error => { return error; });

  return errors;
};

export default emailValidationMessage;

import { createAction } from 'redux-act';

import { postCreateJoinRequest } from '../../api/join-request';

export const createJoinRequest = createAction('CREATE_JOIN_REQUEST');
export const joinRequestCreated = createAction('JOIN_REQUEST_CREATED');
export const hideJoinRequestCreated = createAction('HIDE_JOIN_REQUEST_CREATED');
export const joinRequestCreationFail = createAction('JOIN_REQUEST_CREATION_FAIL');

export const createJoinRequestForHub = (hubId) => {
  return dispatch => {
    dispatch(createJoinRequest(hubId));

    postCreateJoinRequest(hubId)
      .then(() => {
        dispatch(joinRequestCreated(hubId));
        setTimeout(() => dispatch(hideJoinRequestCreated(hubId)), 2500);
      })
      .catch(() => dispatch(joinRequestCreationFail(hubId)));
  };
};

import { createAction } from 'redux-act';

import { getFamilyBranding } from '../../api/family-branding';

export const fetchingFamilyBranding = createAction('FETCHING_FAMILY_BRANDING');
export const familyBrandingLoadedSuccessfully = createAction('FAMILY_BRANDING_LOADED_SUCCESSFULLY');
export const familyBrandingLoadingFail = createAction('FAMILY_BRANDING_LOADING_FAIL');

export function loadFamilyBranding() {
  return dispatch => {
    dispatch(fetchingFamilyBranding());

    getFamilyBranding()
      .then(response => dispatch(familyBrandingLoadedSuccessfully(response.data)))
      .catch(() => dispatch(familyBrandingLoadingFail()));
  };
}

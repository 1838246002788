import Color from 'color';
import PropTypes from 'prop-types';
import React from 'react';
import ThemeProvider from '@influitive/secret-garden/lib/theme-provider';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { compose, setPropTypes, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { css, Global } from '@emotion/core';

import * as actions from './store/actions';
import Home from './components/home/index';
import NewSession from './components/new-session/index';
import SignUp from './components/sign-up/index';
import vars from './common/vars';
import { themeSelector } from './store/family-branding/selectors';
import BodyClassProvider from './body-class-provider';

const buildTheme = (primaryColor = vars.primary) => ({
  colors: {
    primary: [
      primaryColor,
      Color(primaryColor).darken(0.25).string(),
      Color(primaryColor).lighten(0.25).string(),
      Color(primaryColor).desaturate(0.1).lighten(0.7).string()
    ]
  }
});

export default compose(
  connect(
    state => themeSelector(state),
    {
      loadSSOLink: actions.loadSSOLink,
      loadFamilyBranding: actions.loadFamilyBranding,
      loadCurrentUser: actions.loadCurrentUser,
      loadHubs: actions.loadHubs
    }
  ),
  setPropTypes({
    loadCurrentUser: PropTypes.func.isRequired,
    loadSSOLink: PropTypes.func.isRequired,
    loadFamilyBranding: PropTypes.func.isRequired,
    loadHubs: PropTypes.func.isRequired,
    favicon: PropTypes.string
  }),
  lifecycle({
    componentDidMount() {
      this.props.loadCurrentUser();
      this.props.loadSSOLink();
      this.props.loadFamilyBranding();
      this.props.loadHubs();
    }
  })
)(({ primaryColor, favicon }) => (
  <ThemeProvider theme={buildTheme(primaryColor)} >
    {favicon && <Helmet> <link rel="shortcut icon" href={favicon} /> </Helmet>}
    <Global styles={css`body { margin: 0; }`}/>
    <BrowserRouter>
      <Switch>
        <BodyClassProvider>
          <Route exact path="/" component={Home}/>
          <Route path="/sign_in" component={NewSession}/>
          <Route path="/sign_up" component={SignUp}/>
        </BodyClassProvider>
      </Switch>
    </BrowserRouter>
  </ThemeProvider>
));

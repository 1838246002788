import axios from 'axios';

const csrfTokenInput = document.getElementById('csrf-token');
const params = csrfTokenInput ? { _csrf_token: csrfTokenInput.value } : {};

const client = axios.create({
  withCredentials: true
});

const clientWithCsrf = axios.create({
  withCredentials: true,
  params
});

export const get = client.get;
export const post = clientWithCsrf.post;
export const deleteRequest = clientWithCsrf.delete;

import { createReducer } from 'redux-act';
import {
  fetchingCurrentUser,
  currentUserLoadedSuccessfully,
  currentUserLoadingFail,
  currentUserLoggedOut
} from './actions';

const defaultState = { data: null, loadingCompleted: false };

const currentUserReducer = createReducer({
  [fetchingCurrentUser]: state => ({ ...state, ...defaultState }),
  [currentUserLoadedSuccessfully]: (state, payload) => ({ ...state, loadingCompleted: true, data: payload || null }),
  [currentUserLoadingFail]: state => ({ ...state, ...defaultState }),
  [currentUserLoggedOut]: state => ({ ...state, ...defaultState })
}, defaultState);

export default currentUserReducer;

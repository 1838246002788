import camelcaseKeys from 'camelcase-keys';

export const gatewayDescriptionSelector = state => ({
  loggedOutHeaderText: state.familyBranding.logged_out_header_text,
  loggedInHeaderText: state.familyBranding.logged_in_header_text,
  joinFamilyButtonText: state.familyBranding.join_family_button_text,
  headerTitle: state.familyBranding.header_title,
  attachmentLinks: camelcaseKeys(state.familyBranding.attachment_links || {}),
  loadingCompleted: state.familyBranding.loadingCompleted
});

export const themeSelector = state => ({
  primaryColor: state.familyBranding.primary_color,
  favicon: state.familyBranding.favicon
});

export const footerSelector = state => ({
  footer: state.familyBranding.footer,
  defaultFooter: state.familyBranding.default_footer,
  loadingCompleted: state.familyBranding.loadingCompleted
});

export const emptyStateTextSelector = state => ({
  emptyStateText: state.familyBranding.empty_state_text
});

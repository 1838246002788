import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';

import { GatewayDescription, CreateAccount, SignUpLink } from './styles';
import Button from '../button';
import { qaSelector } from '../../utils';

export default compose(
  setPropTypes({
    signIn: PropTypes.string,
    signUp: PropTypes.string,
    signInText: PropTypes.string,
    descriptionText: PropTypes.string
  }),
  setDisplayName('SignedOutElements')
)(({ signIn, signUp, signInText, descriptionText }) => (
  <React.Fragment>
    <GatewayDescription className="c-description" dangerouslySetInnerHTML={{ __html: descriptionText }} />
    <Button className="c-join-now" href={signIn || ''} title={signInText} data-qa={qaSelector('join-now-button')}
      variant={['primary', 'join']}>
      {signInText}
    </Button>
    {signUp &&
      <CreateAccount className="c-create-account">
        Create an account!&nbsp;
        <SignUpLink className="c-sign-up-link" href={signUp} title="Sign Up" data-qa={qaSelector('sign-up-link')}>
          Sign Up
        </SignUpLink>
      </CreateAccount>
    }
  </React.Fragment>
));

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

import Hub from '../hub';
import * as selectors from '../../store/selectors';
import HubAuthenticationForm from '../hub-authentication-form';

const HubListContainer = styled('div')`
  display: flex;
  justify-content: center;
`;

const HubList = styled('div')`
  padding-top: ${themeGet('spacing.4')}px;

  @media only screen and (min-width: ${themeGet('sizes.desktop')}px) {
    min-width: ${themeGet('sizes.desktop')}px;
    max-width: 1200px;
  }

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    padding: ${themeGet('spacing.0')}px;
    width: 100%;
  }
`;

const EmptyStateText = styled('div')`
  padding: ${themeGet('spacing.7')}px ${themeGet('spacing.4')}px;
`;

export default compose(
  setPropTypes({
    currentUser: PropTypes.object,
    companies: PropTypes.array,
    loadingCompleted: PropTypes.bool
  }),
  connect(
    state => ({
      ...selectors.currentUserSelector(state),
      ...selectors.hubsSelector(state),
      ...selectors.emptyStateTextSelector(state)
    }),
  ),
  setDisplayName('HubList')
)(({ currentUser, companies, emptyStateText }) => (
  <HubListContainer className="c-hub-list-container">
    <HubAuthenticationForm/>
    {companies.length > 0 ?
      <HubList className="c-hub-list">
        {companies.map(company =>
          <Hub className="c-hub-list-hub" key={company.orgId} {...company} currentUser={currentUser}/>
        )}
      </HubList> :
      <EmptyStateText className="c-hub-list-empty-state" dangerouslySetInnerHTML={{ __html: emptyStateText }}/>
    }
  </HubListContainer>
));

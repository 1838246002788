import { createAction } from 'redux-act';

import { createUser } from '../../api/session';

export const signUpRequest = createAction('SIGN_UP_REQUEST');
export const signUpSuccess = createAction('SIGN_UP_SUCCESS');
export const signUpFailure = createAction('SIGN_UP_FAILURE');

export function signUp(formData) {
  return dispatch => {
    dispatch(signUpRequest());
    createUser(formData)
      .then(() => dispatch(signUpSuccess()))
      .catch(error => dispatch(signUpFailure(error.response.data)));
  };
}

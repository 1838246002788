import { createAction } from 'redux-act';

import { getSsoLinks } from '../../api/sso-links';

export const fetchingSsoLink = createAction('FETCHING_SSO_LINK');
export const ssoLinkLoadedSuccessfully = createAction('SSO_LINK_LOADED_SUCCESSFULLY');
export const ssoLinkLoadingFail = createAction('SSO_LINK_LOADING_FAIL');

export function loadSSOLink() {
  return dispatch => {
    dispatch(fetchingSsoLink());

    getSsoLinks()
      .then(response => dispatch(ssoLinkLoadedSuccessfully(response.data)))
      .catch(() => dispatch(ssoLinkLoadingFail()));
  };
}

import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

export const GatewayDescription = styled('div')`
  font-size: ${themeGet('fonts.size.5')}px;
  max-width: ${themeGet('sizes.phone')}px;
  line-height: ${themeGet('fonts.lineHeight.10')}px;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    font-size: ${themeGet('fonts.size.3')}px;
    line-height: ${themeGet('fonts.lineHeight.6')}px;
  }
`;
export const CompanyInfoTop = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 3;
`;

export const CompanyInfoBottom = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

export const MobileAppLogin = styled('div')`
  height: 100vh;
  color: ${themeGet('colors.white')};
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

export const Title = styled('h1')`
  font-size: ${themeGet('spacing.6')}px;
  margin-top: ${themeGet('spacing.6')}px;
  font-weight: ${themeGet('fonts.weight.2')};
  padding: ${themeGet('spacing.2')} 0;
  margin: 0;
`;

export const SignUpLink = styled('a')`
  color: ${themeGet('colors.white')};

  &:hover, &:focus {
    text-decoration: none;
  }
`;

export const CompanyLogo = styled('img')`
  width: 300px;
`;

export const CreateAccount = styled('div')`
  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    font-size: ${themeGet('fonts.size.1')}px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName, branch, renderNothing } from 'recompose';

import * as selectors from '../../store/selectors';
import SessionButtons from '../session-buttons/index';
import SignedOutElements from './signed-out-elements';
import { GatewayDescriptionContainer, CompanyLogo, GatewayDescription, Title, Content } from './styles';

export default compose(
  connect(
    state => ({
      ...selectors.currentUserSelector(state),
      ...selectors.gatewayDescriptionSelector(state),
      ...selectors.ssoLinkSelector(state)
    })
  ),
  setPropTypes({
    loggedOutHeaderText: PropTypes.string,
    loggedInHeaderText: PropTypes.string,
    joinFamilyButtonText: PropTypes.string,
    headerTitle: PropTypes.string,
    attachmentLinks: PropTypes.object,
    loadingCompleted: PropTypes.bool,
    currentUser: PropTypes.object,
    signIn: PropTypes.string,
    signUp: PropTypes.string
  }),
  branch(
    ({ loadingCompleted }) => !loadingCompleted,
    renderNothing
  ),
  setDisplayName('GatewayDescription')
)(({
  loggedOutHeaderText,
  loggedInHeaderText,
  joinFamilyButtonText,
  headerTitle,
  attachmentLinks: { headerBackground, logo },
  currentUser,
  signIn,
  signUp
}) => (
  <GatewayDescriptionContainer className="c-description-container" style={{ backgroundImage: `url(${headerBackground})` }}>
    <CompanyLogo className="c-description-company-logo" src={logo} alt="Company Logo"/>
    <SessionButtons/>

    <Content className="c-description-inner-container">
      <Title className="c-description-title" dangerouslySetInnerHTML={{ __html: headerTitle }}/>
      {currentUser
        ? <GatewayDescription className="c-description" dangerouslySetInnerHTML={{ __html: loggedInHeaderText }}/>
        : <SignedOutElements className="c-description" signIn={signIn} signUp={signUp} signInText={joinFamilyButtonText}
          descriptionText={loggedOutHeaderText}/>}
    </Content>
  </GatewayDescriptionContainer>
));

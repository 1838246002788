import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName, withHandlers, lifecycle, withState, branch, renderNothing } from 'recompose';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import Input from '@influitive/secret-garden/lib/form-inputs/input';
import MediaQuery from 'react-responsive';
import * as R from 'ramda';

import PasswordValidation from './password-validation';
import Button from '../button';
import Footer from '../footer/index';
import { qaSelector } from '../../utils';
import * as selectors from '../../store/selectors';
import { loadCurrentUser, loadHubs, signUp } from '../../store/actions';
import emailValidationMessage from './email-validation-message';

const SignUpContainer = styled('div')`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormContainer = styled('form')`
  max-width: 400px;
  width 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormControl = styled(Input)`
  padding-left: ${themeGet('spacing.2')}px;
  padding-right: ${themeGet('spacing.2')}px;
  padding-top: ${themeGet('spacing.4')}px;
  #email, #name, #password {
    height: ${themeGet('spacing.6')}px;
  }

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    #email, #name, #password {
      font-size: ${themeGet('fonts.size.3')}px;
    }
  }
`;

const SubmitButtonContainer = styled('div')`
  max-width: 400px;
  width 100%;
  padding-top: ${themeGet('spacing.4')}px;
  padding-bottom ${themeGet('spacing.3')}px;
  padding-left ${themeGet('spacing.2')}px;
  padding-right ${themeGet('spacing.2')}px;
`;

const StrengthPasswordContainer = styled('div')`
  max-width: 400px;
  width 100%;
  padding-top: 14px;
  padding-right: ${themeGet('spacing.2')}px;
  padding-left: ${themeGet('spacing.2')}px;
`;


const Logo = styled('img')`
  width: 80%;
  height: auto;
  padding-top: ${themeGet('spacing.2')}px;
  padding-bottom: ${themeGet('spacing.2')}px;
`;

const SingInLinkContainer = styled('div')`
  max-width: 400px;
  width 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignInLink = styled('a')`
  color: ${themeGet('colors.neutral.2')};
  padding-left: ${themeGet('spacing.1')}px;
`;

const SessionFooter = styled(Footer)`
  height: 10%;
`;

export default compose(
  connect(
    state => ({
      ...selectors.gatewayDescriptionSelector(state),
      ...selectors.signUpSelector(state),
      ...selectors.currentUserSelector(state)
    }), { loadCurrentUser, loadHubs, signUp }
  ),
  withState('passwordLength', 'setPasswordLength', false),
  withState('passwordStrength', 'setPasswordStrength', false),
  withState('nameFilled', 'setNameFilled', false),
  withState('emailValid', 'setEmailValid', []),
  lifecycle({
    componentDidUpdate() {
      if (this.props.currentUser) {
        this.props.history.push('/');
      }

      if (!this.props.signedUp) {
        return;
      }

      this.props.loadCurrentUser();
      this.props.loadHubs();
      this.props.history.push('/');
    }
  }),
  withHandlers({
    strengthPasswordCheck: () => password => {
      let checkCount = 0;
      ['\\d', '[A-Z]', '[a-z]', '[!"#$%&\'()*+,-./:;<=>?@[\\]^_‘{|}~\\s]'].forEach((regexp) => {
        if (password.match(new RegExp(regexp))) checkCount = checkCount + 1;
      });

      return checkCount >= 3;
    }
  }),
  withHandlers({
    handleSubmit: ({ signUp }) => event => {
      event.preventDefault();
      const sessionFormData = new FormData(event.currentTarget);
      signUp(sessionFormData);
    },
    handlePasswordValidation: ({ setPasswordLength, setPasswordStrength, strengthPasswordCheck }) => event => {
      setPasswordLength(event.currentTarget.value.length >= 12);
      setPasswordStrength(strengthPasswordCheck(event.currentTarget.value));
    },
    handleEmailValidation: ({ setEmailValid }) => event => {
      let email = event.currentTarget.value;
      setEmailValid(emailValidationMessage(email));
    },
    handleEmailAlreadyExistsMessages: ({ errorMessages }) => () => {
      if (errorMessages.email && errorMessages.email[0] === 'already exist') {
        return 'This email already exists. Please try signing in instead.';
      }
      return errorMessages.email;
    },
    handleNameValidation: ({ setNameFilled }) => event => {
      setNameFilled(event.currentTarget.value.length > 0);
    },
    handleError: ({ signUpError, errorMessages }) => field => {
      return (signUpError && errorMessages[field]);
    },
    passwordFieldValidation: ({ passwordStrength, passwordLength }) => () => {
      return (!passwordStrength || !passwordLength);
    }
  }),
  withHandlers({
    submitButtonDisable: ({ nameFilled, passwordFieldValidation, emailValid }) => () => {
      return (!nameFilled || !R.isEmpty(emailValid) ||  passwordFieldValidation());
    }
  }),
  setPropTypes({
    handleSubmit: PropTypes.func.isRequired,
    signUpError: PropTypes.bool,
    errorMessages: PropTypes.object,
    signedUp: PropTypes.bool,
    attachmentLinks: PropTypes.object,
    loadFamilyBranding: PropTypes.func.isRequired,
    loadingCompleted: PropTypes.bool,
    currentUser: PropTypes.object
  }),
  branch(
    ({ loadingCompleted }) => !loadingCompleted,
    renderNothing
  ),
  setDisplayName('SignUp')
)(({
  handleSubmit,
  passwordLength,
  passwordStrength,
  handlePasswordValidation,
  attachmentLinks,
  errorMessages,
  handleError,
  handleNameValidation,
  handleEmailValidation,
  submitButtonDisable,
  emailValid,
  handleEmailAlreadyExistsMessages
}) => (
  <div id="ADVOCATE-GATEWAY-FE-SIGN-UP">
    <SignUpContainer className="c-sign-up-container">
      <FormContainer className="c-form-container" onSubmit={handleSubmit} >
        <Logo className="c-logo" src={attachmentLinks.logo} />
        <FormControl
          className="c-form-control"
          label="Full Name"
          type="name"
          id="name"
          name="name"
          onChange={handleNameValidation}
          hasError={handleError('name')}
          errorMessage={errorMessages.name}
          data-qa={qaSelector('sign-in-form-name')}/>
        <FormControl
          className="c-form-control"
          label="Email"
          id="email"
          name="email"
          onChange={handleEmailValidation}
          hasError={emailValid[0] || handleError('email')}
          errorMessage={emailValid[0] || handleEmailAlreadyExistsMessages()}
          data-qa={qaSelector('sign-in-form-email')}/>
        <FormControl
          className="c-form-control"
          onChange={handlePasswordValidation}
          label="Password"
          type="password"
          id="password"
          name="password"
          hasError={handleError('password')}
          errorMessage={errorMessages.password}
          data-qa={qaSelector('sign-in-form-password')}/>
        <StrengthPasswordContainer className="c-strength-password-container">
          <PasswordValidation className="c-password-validation" valid={passwordLength}>
            Minimum 12 characters.
          </PasswordValidation>
          <PasswordValidation className="c-password-validation" valid={passwordStrength}>
            At least 3 of uppercase letter, lowercase letter, number or symbol.
          </PasswordValidation>
        </StrengthPasswordContainer>
        <SubmitButtonContainer className="c-submit-button-container">
          <Button
            className="c-submit-button"
            type="submit"
            variant="authorization"
            disabled={submitButtonDisable()}
            tooltipId="signup-tooltip"
            tooltipText="Please check that you have filled out your Full Name, Email, and Password.">
            Sign Up
          </Button>
        </SubmitButtonContainer>
        <SingInLinkContainer className="c-sign-in-link-container">
          Have an account? <SignInLink className="c-sign-in-link" href="/sign_in">Sign In</SignInLink>
        </SingInLinkContainer>
      </FormContainer>
      <MediaQuery minWidth={576}>
        <SessionFooter className="c-session-footer"/>
      </MediaQuery>
    </SignUpContainer>
  </div>
));

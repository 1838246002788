import { createReducer } from 'redux-act';
import {
  fetchingAuthenticationPayload,
  authenticationPayloadLoadedSuccessfully,
  authenticationPayloadLoadingFail
} from './actions';

const defaultState = { url: null, payload: null, loadingCompleted: false };

const authenticationPayloadReducer = createReducer({
  [fetchingAuthenticationPayload]: state => ({ ...state, loadingCompleted: false }),
  [authenticationPayloadLoadedSuccessfully]: (state, payload) => ({ ...state, loadingCompleted: true, ...payload }),
  [authenticationPayloadLoadingFail]: state => ({ ...state, loadingCompleted: false }),
}, defaultState);

export default authenticationPayloadReducer;

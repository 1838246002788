import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet } from 'styled-system';

export const DescriptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: ${themeGet('spacing.7')}px;
  box-sizing: border-box;
  line-height: ${themeGet('fonts.lineHeight.8')}px;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    padding: ${themeGet('spacing.3')}px;
    flex-grow: 1;
    justify-content: normal;
    line-height: ${themeGet('fonts.lineHeight.6')}px;
  }

  @media only screen and (min-width: ${themeGet('sizes.phone')}px) and (max-width: ${themeGet('sizes.tablet')}px) {
    padding-left: ${themeGet('spacing.4')}px;
    justify-content: normal;
  }
`;

export const Image = styled('img')`
  width: 375px;
  transition: height 0.3s;
  object-fit: cover;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    height: ${props => props.isExpanded ? '211' : '0'}px;
    width: 100%;
  }

  @media only screen and (min-width: ${themeGet('sizes.phone')}px) and (max-width: ${themeGet('sizes.tablet')}px) {
    width: 200px;
    height: 112px;
    align-self: start;
  }
`;

export const ImageLink = styled('a')`
  display: flex;
`;

export const Text = styled('div')`
  color: ${themeGet('colors.neutral.1')};
  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    ${props => !props.isExpanded ? null : 'max-height: 45px;'}
  }
`;

export const Description = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${themeGet('spacing.3')}px;
  transition: 0.3s;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    position: relative;
    overflow-y: hidden;
    width: auto;
    flex-grow: 1;
    justify-content: flex-start;
    ${props => !props.isExpanded ? null : expandedDescription}
  }
`;

export const Separator = styled('div')`
  width: 50px;
  height: ${themeGet('spacing.3')}px;
  border-top: 1px solid ${themeGet('colors.primary.0')};
`;

export const Name = styled('a')`
  font-size: ${themeGet('fonts.size.7')}px;
  color: ${themeGet('colors.primary.0')};
  text-decoration: none;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    padding-bottom: ${themeGet('spacing.2')}px;
    font-size: ${themeGet('fonts.size.6')}px;
  }

  @media only screen and (min-width: ${themeGet('sizes.phone')}px) and (max-width: ${themeGet('sizes.tablet')}px) {
    font-size: ${themeGet('fonts.size.6')}px;
  }
`;

export const Hub = styled('div')`
  display: flex;
  padding: ${themeGet('spacing.3')}px;
  margin: ${themeGet('spacing.5')}px ${themeGet('spacing.4')}px;
  font-size: ${themeGet('fonts.size.3')}px;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    flex-direction: column;
    box-shadow: ${themeGet('container.shadow.2')};
    padding: ${themeGet('spacing.0')}px;
    margin: ${themeGet('spacing.2')}px;

    border-radius: ${themeGet('container.radius.1')}px;
    border: 1px transparent;
    overflow: hidden;
  }
`;

export const InfoMessage = styled('div')`
  background: ${themeGet('colors.success.2')};
  color: ${themeGet('colors.success.1')};
  font-size: ${themeGet('fonts.size.3')}px;
  line-height: ${themeGet('fonts.lineHeight.6')}px;
  margin-bottom: ${themeGet('spacing.2')}px;
  padding: ${themeGet('spacing.1')}px ${themeGet('spacing.2')}px;
  border-radius: 4px;

  @media only screen and (min-width: ${themeGet('sizes.phone')}px) and (max-width: ${themeGet('sizes.tablet')}px) {
    margin-bottom: ${themeGet('spacing.2')}px;
  }

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    font-size: ${themeGet('fonts.size.1')}px;
    line-height: ${themeGet('fonts.lineHeight.4')}px;
  }
`;

export const JoinedLabel = styled('div')`
  margin-left: ${themeGet('spacing.3')}px;
  font-size: ${themeGet('fonts.size.3')}px;
  line-height: ${themeGet('fonts.lineHeight.6')}px;
  align-items: center;
  display: flex;
  line-height: 24px;
`;

export const JoinedLabelCheck = styled('span')`
  color: ${themeGet('colors.success.0')};
`;

export const JoinedLabelText = styled('span')`
  margin-left: ${themeGet('spacing.2')}px;
  height: ${themeGet('fonts.lineHeight.7')}px;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    font-size: ${themeGet('fonts.size.1')}px;
    line-height: ${themeGet('fonts.lineHeight.4')}px;
  }
`;

export const NameContainer = styled('div')`
  display: flex;

  @media only screen and (min-width: ${themeGet('sizes.tablet')}px) {
    margin: ${themeGet('spacing.2')}px 0;
  }
`;

const expandedDescription = () => css`
  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2em;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
  }
`;

import { createReducer } from 'redux-act';
import {
  signUpRequest,
  signUpSuccess,
  signUpFailure
} from './actions';

const defaultState = { errorMessages: {} };

const signUpReducer = createReducer({
  [signUpRequest]: state => ({ ...state }),
  [signUpSuccess]: state => ({ ...state, signedUp: true }),
  [signUpFailure]: (state, payload) => ({ ...state, errorMessages: payload.errors,  signUpError: true })
}, defaultState);

export default signUpReducer;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withHandlers, setDisplayName } from 'recompose';

const bodyClassNames = {
  '/': 'c-home-root',
  '/sign_in': 'c-sign-in-root',
  '/sign_up': 'c-sign-up-root'
};

export default withRouter(compose(
  withHandlers({
    setClassToBody: () => pathname => {
      let body = document.getElementsByTagName('body')[0];
      body.removeAttribute('class');
      body.classList.add(bodyClassNames[pathname]);
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.setClassToBody(this.props.history.location.pathname);
      this.unlisten = this.props.history.listen(location => {
        this.props.setClassToBody(location.pathname);
      });
    },
    componentDidUnmount() {
      this.unlisten();
    }
  }),
  setDisplayName('BodyClassProvider')
)(({ children }) => (
  <React.Fragment>
    {children}
  </React.Fragment>
)));

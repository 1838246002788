import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName, branch, renderNothing } from 'recompose';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

import * as selectors from '../../store/selectors';
import { qaSelector } from '../../utils';

const Footer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`;

const DefaultFooter = styled('img')`
  height: ${themeGet('spacing.4')}px;
  margin: ${themeGet('spacing.2')}px ${themeGet('spacing.0')}px;
`;

export default compose(
  setPropTypes({
    defaultFooter: PropTypes.string,
    footer: PropTypes.string,
    loadingCompleted: PropTypes.bool
  }),
  connect(state => selectors.footerSelector(state)),
  branch(
    ({ loadingCompleted }) => !loadingCompleted,
    renderNothing
  ),
  setDisplayName('Footer')
)(({ footer, defaultFooter }) => (
  <Footer className="c-footer" data-qa={qaSelector('footer')}>
    {footer
      ? <div className="c-footer-content" dangerouslySetInnerHTML={{ __html: footer }}/>
      : <DefaultFooter className="c-footer-default" src={defaultFooter} alt="Influitive Footer"/>}
  </Footer>
));

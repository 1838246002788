import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName } from 'recompose';

import { CompanyLogo, MobileHeaderContainer } from './styles';
import SessionButtons from '../session-buttons/index';
import * as selectors from '../../store/selectors';

export default compose(
  connect(
    state => ({
      ...selectors.gatewayDescriptionSelector(state)
    })
  ),
  setPropTypes({
    attachmentLinks: PropTypes.object,
  }),
  setDisplayName('MobileHeader')
)(({
  attachmentLinks: { logo }
}) => (
  <MobileHeaderContainer className="c-mobile-header-container">
    <CompanyLogo className="c-mobile-company-logo" src={logo} alt="Company Logo"/>
    <SessionButtons/>
  </MobileHeaderContainer>
));

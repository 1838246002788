import { createAction } from 'redux-act';

import { getHubList } from '../../api/hubs';

export const fetchingHubs = createAction('FETCHING_HUBS');
export const hubsLoadedSuccessfully = createAction('HUBS_LOADED_SUCCESSFULLY');
export const hubsLoadingFail = createAction('HUBS_LOADING_FAIL');

export function loadHubs() {
  return dispatch => {
    dispatch(fetchingHubs());

    getHubList()
      .then(response => dispatch(hubsLoadedSuccessfully(response.data)))
      .catch(() => dispatch(hubsLoadingFail()));
  };
}

import { createReducer } from 'redux-act';
import { createJoinRequest, joinRequestCreated, joinRequestCreationFail, hideJoinRequestCreated } from './actions';

const defaultState = {};

const familyBrandingReducer = createReducer({
  [createJoinRequest]: (state, hubId) => {
    const newState = { ...state };
    newState[hubId] = { isLoading: true };
    return newState;
  },
  [joinRequestCreated]: (state, hubId) => {
    const newState = { ...state };
    newState[hubId] = { isLoading: false, created: true };
    return newState;
  },
  [hideJoinRequestCreated]: (state, hubId) => {
    const newState = { ...state };
    newState[hubId] = { isLoading: false, created: false, disabled: true };
    return newState;
  },
  [joinRequestCreationFail]: (state, hubId) => {
    const newState = { ...state };
    newState[hubId] = { isLoading: false, created: false, error: true };
    return newState;
  }
}, defaultState);

export default familyBrandingReducer;

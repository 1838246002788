import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName, branch, renderNothing } from 'recompose';

import * as selectors from '../../store/selectors';
import { MobileAppLogin, CompanyLogo, Title, CompanyInfoTop, CompanyInfoBottom, CreateAccount, SignUpLink } from './styles';
import Button from '../button';
import { qaSelector } from '../../utils';


export default compose(
  connect(
    state => ({
      ...selectors.currentUserSelector(state),
      ...selectors.gatewayDescriptionSelector(state),
      ...selectors.ssoLinkSelector(state)
    })
  ),
  setPropTypes({
    loggedOutHeaderText: PropTypes.string,
    loggedInHeaderText: PropTypes.string,
    joinFamilyButtonText: PropTypes.string,
    headerTitle: PropTypes.string,
    attachmentLinks: PropTypes.object,
    loadingCompleted: PropTypes.bool,
    currentUser: PropTypes.object,
    signIn: PropTypes.string,
    signUp: PropTypes.string
  }),
  branch(
    ({ loadingCompleted }) => !loadingCompleted,
    renderNothing
  ),
  setDisplayName('MobileLogin')
)(({
  joinFamilyButtonText,
  headerTitle,
  attachmentLinks: { headerBackground, logo },
  signIn,
  signUp
}) => (
  <MobileAppLogin style={{ backgroundImage: `url(${headerBackground})` }}>
    <CompanyInfoTop className="c-company-info-top">
      <CompanyLogo className="c-mobile-company-logo" src={logo} alt="Company Logo"/>
      <Title className="c-mobile-company-title" dangerouslySetInnerHTML={{ __html: headerTitle }}/>
    </CompanyInfoTop>
    <CompanyInfoBottom className="c-company-info-bottom">
      <Button className="c-join-now-button" href={signIn || ''} title={joinFamilyButtonText} data-qa={qaSelector('join-now-button')}
        variant={['filled', 'join']}>
        {joinFamilyButtonText}
      </Button>
      {signUp && <CreateAccount className="c-create-account">
        Create an account!&nbsp;
        <SignUpLink className="c-sign-up-link" href={signUp} title="Sign Up" data-qa={qaSelector('sign-up-link')}>
          Sign Up
        </SignUpLink>
      </CreateAccount>}
    </CompanyInfoBottom>
  </MobileAppLogin>
));

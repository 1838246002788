import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import { compose, setPropTypes, setDisplayName, withHandlers, withProps, withState, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import AsyncButton from '@influitive/secret-garden/lib/async-button';
import { Icon } from 'infl-icons';
import * as R from 'ramda';

import { qaSelector } from '../../utils';
import * as selectors from '../../store/selectors';
import { loadAuthenticationPayload, createJoinRequestForHub } from '../../store/actions';
import {
  Description,
  DescriptionContainer,
  Hub,
  Image,
  ImageLink,
  InfoMessage,
  Name,
  NameContainer,
  Separator,
  JoinedLabel,
  JoinedLabelCheck,
  JoinedLabelText,
  Text
} from './styles';

const infoMessageShownList = () => JSON.parse(localStorage.getItem('infoMessageShownHubs') || '{}');
const setInfoMessageShownList = list => localStorage.setItem('infoMessageShownHubs', JSON.stringify(list));

const HubName = ({ name, joined, hubLinkClickHandler, url }) => (
  <NameContainer>
    <Name className="c-hub-name" href={url} title={name} onClick={hubLinkClickHandler} data-qa={qaSelector('hub-name')}>{name}</Name>
    {joined &&
      <JoinedLabel className="c-hub-join-label">
        <JoinedLabelCheck className='c-hub-label-check'>
          <Icon icon="check"/>
        </JoinedLabelCheck>
        <JoinedLabelText className='c-hub-label-text'>
          Joined
        </JoinedLabelText>
      </JoinedLabel>
    }
  </NameContainer>
);

const HubInfoMessage = ({ infoMessage, orgId }) => (
  infoMessage && !infoMessageShownList()[orgId]
    ? <InfoMessage className="c-hub-join-message" data-qa={qaSelector('join-message')}>{infoMessage}</InfoMessage>
    : null
);

const getButtonTexts = R.pipe(
  ({ disabled, joined, shouldRequestAccess, invited, rejected }) => R.cond([
    [R.always(joined), R.always('Enter Hub')],
    [R.always(rejected), R.always('Access Denied')],
    [R.always(disabled), R.always('Access Requested')],
    [R.always(shouldRequestAccess && !invited), R.always('Request access')],
    [R.T, R.always('Join Hub')]
  ])(),
  text => ([text, text, 'Access Requested', 'Request failed'])
);

const getButtonStatus = R.cond([
  [R.isNil, R.always('default')],
  [R.prop('isLoading'), R.always('pending')],
  [R.prop('error'), R.always('error')],
  [R.prop('created'), R.always('success')],
  [R.T, R.always('default')]
]);

const disabledCondition = (props) => (
  props.accessRequested || props.joinRequestStatus && props.joinRequestStatus.disabled || props.rejected
);

export default compose(
  withTheme,
  withState('isExpanded', 'toggleExpanded', true),
  connect(
    (state, { orgId }) => ({ joinRequestStatus: selectors.joinRequests(state, orgId) }),
    { loadAuthenticationPayload, createJoinRequestForHub }
  ),
  withProps(props => {
    const disabled = disabledCondition(props) || props.invitationPending;

    const buttonTexts = getButtonTexts({ ...props, disabled });
    const buttonStatus = getButtonStatus(props.joinRequestStatus);
    const infoMessage = props.invited && 'You can now join this hub!';

    return { disabled, buttonTexts, buttonStatus, infoMessage };
  }),
  withHandlers({
    onHubExpand: ({ theme, toggleExpanded }) => () => {
      const mobileView = window.matchMedia(`(max-width: ${theme.sizes.phone}px)`).matches;
      if (!mobileView) {
        return;
      }

      toggleExpanded(R.complement(isExpanded => isExpanded));
    },
    hubLinkClickHandler: props => event => {
      if (!props.currentUser) return;

      event.preventDefault();

      if (props.shouldRequestAccess && !props.invited) {
        props.createJoinRequestForHub(props.orgId);
      } else {
        props.loadAuthenticationPayload(props.id);
      }
    }
  }),
  setPropTypes({
    description: PropTypes.string,
    url: PropTypes.string,
    imageUrl: PropTypes.string,
    buttonText: PropTypes.string,
    name: PropTypes.string,
    hubLinkClickHandler: PropTypes.func,
    onHubExpand: PropTypes.func.isRequired
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.infoMessage || !this.props.currentUser) {
        return;
      }

      const infoMessageShownHubs = infoMessageShownList();

      if (infoMessageShownHubs[this.props.orgId]) {
        return;
      }

      infoMessageShownHubs[this.props.orgId] = true;
      setInfoMessageShownList(infoMessageShownHubs);
    }
  }),
  setDisplayName('Hub')
)(({
  description,
  url,
  orgId,
  buttonTexts,
  imageUrl,
  name,
  hubLinkClickHandler,
  onHubExpand,
  isExpanded,
  infoMessage,
  joined,
  buttonStatus,
  disabled
}) => (
  <Hub className="c-hub" data-qa={qaSelector(name)}>
    <ImageLink className="c-hub-image-link" href={url} title={name} onClick={hubLinkClickHandler} data-qa={qaSelector('hub-image')}>
      <Image className="c-hub-image" src={imageUrl} alt={name} isExpanded={isExpanded}/>
    </ImageLink>
    <DescriptionContainer className="c-hub-description-container">
      <Description className="c-hub-description" isExpanded={isExpanded} onClick={onHubExpand}>
        <HubInfoMessage infoMessage={infoMessage} orgId={orgId}/>
        <HubName name={name} url={url} joined={joined} hubLinkClickHandler={hubLinkClickHandler}/>
        <Separator/>
        <Text className="c-hub-text" isExpanded={isExpanded}>{description}</Text>
      </Description>
      <AsyncButton
        className="c-hub-enter-hub"
        href={url}
        disabled={disabled}
        onClick={hubLinkClickHandler}
        variant="primary"
        data-qa={qaSelector('enter-hub')}
        text={buttonTexts}
        status={buttonStatus}/>
    </DescriptionContainer>
  </Hub>
));

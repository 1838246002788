import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName, withProps, defaultProps, componentFromProp } from 'recompose';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import * as R from 'ramda';
import Tooltip from '@influitive/secret-garden/lib/tooltip';

const base = ({ theme }) => css`
  border: 1px solid ${theme.colors.white};
  padding: ${theme.spacing[2]}px ${theme.spacing[3]}px;
  border-radius: ${theme.container.radius[1]}px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  font-size: ${theme.fonts.size[3]}px;
`;

const primary = ({ theme }) => css`
  background-color: ${theme.colors.white};
  color: ${theme.colors.primary[0]};

  &:hover {
    transition: background-color 0.3s ease;
    background: transparent;
    color: ${theme.colors.white};
  }
`;

const inverse = ({ theme }) => css`
  background: transparent;
  color: ${theme.colors.white};

  &:hover {
    transition: background-color 0.3s ease;
    background: ${theme.colors.white};
    color: ${theme.colors.primary[0]};
  }
`;

const filled = ({ theme }) => css`
  background-color: ${theme.colors.primary[0]};
  color: ${theme.colors.white};
  border: none;

  &:hover {
    transition: background-color 0.3s ease;
    background: transparent;
    color: ${theme.colors.white};
  }
`;

const session = ({ theme }) => css`
  margin-left: ${theme.spacing[2]}px;
  width: 100px;

  @media only screen and (max-width: ${theme.sizes.phone}px) {
    width: auto;
    border: none;
    background: transparent;
    color: ${theme.colors.white};
    padding: ${theme.spacing[0]}px;
    font-size: ${theme.fonts.size[2]}px;
    margin: ${theme.spacing[2]}px;
    margin-bottom: ${theme.spacing[0]}px;

    &:hover {
      background: transparent;
      color: ${theme.colors.white};
    }
  }
`;

const join = ({ theme }) => css`
  font-size: ${theme.fonts.size[6]}px;
  padding: ${theme.spacing[2]}px 80px;
  margin-top: ${theme.spacing[4]}px;
  margin-bottom: ${theme.spacing[2]}px;

  @media only screen and (max-width: ${theme.sizes.phone}px) {
    font-size: ${theme.fonts.size[4]}px;
    display: inline-block;
    padding: ${theme.spacing[2]}px ${theme.spacing[6]}px;
  }
`;

const authorization = ({ theme }) => css`
  min-width: ${theme.spacing[4]}px;
  width: 100%;
  height: 48px;
  background-color: ${theme.colors.primary[0]};
  border-color: ${theme.colors.primary[0]};
  color: ${theme.colors.white};

  &:hover:not([disabled]) {
    background-color: ${theme.colors.primary[2]};
    border-color: ${theme.colors.primary[2]};
  }

  &:active:not([disabled]) {
    background-color: ${theme.colors.primary[1]};
    border-color: ${theme.colors.primary[1]};
  }
`;

const disabled = ({ theme }) => css`
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary[3]};
  border-color: ${theme.colors.primary[3]};
  cursor: not-allowed;
`;

const variants = { primary, inverse, filled, session, join, authorization };

const BaseLink = ({ children, ...props }) => (
  <a {...props}>{children}</a>
);

const StyledLink = styled(BaseLink)`
  ${base}
  ${props => R.props(props.variant, variants)}
`;

const BaseButton = ({ children, ...props }) => (
  <div data-tip data-for={props.tooltipId}>
    <button {...props}>{children}</button>
    {props.disabled && props.tooltipText && <Tooltip id={props.tooltipId} place="right">
      <TooltipText>{props.tooltipText}</TooltipText>
    </Tooltip>}
  </div>
);

const TooltipText = styled('div')`
  max-width: 220px;
`;

const StyledButton = styled(BaseButton)`
  ${base}
  ${props => R.props(props.variant, variants)}
  ${props => (props.disabled === true ? disabled : '')}
`;

export default compose(
  defaultProps({ variant: ['primary'] }),
  withProps(props => ({ variant: Array.isArray(props.variant) ? props.variant : [props.variant] })),
  withProps(({ href }) => ({
    component: href ? StyledLink : StyledButton
  })),
  setPropTypes({
    variant: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(variants))),
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    disabled: PropTypes.bool,
    href: PropTypes.string,
    tooltipId: PropTypes.string.isRequired,
    tooltipText: PropTypes.string
  }),
  setDisplayName('Button')
)(componentFromProp('component'));

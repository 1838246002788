import { createReducer } from 'redux-act';
import {
  sessionCreateRequest,
  sessionCreateSuccess,
  sessionCreateFailure
} from './actions';

const sessionReducer = createReducer({
  [sessionCreateRequest]: state => ({ ...state }),
  [sessionCreateSuccess]: state => ({ ...state, sessionCreated: true }),
  [sessionCreateFailure]: state => ({ ...state, sessionCreateError: true })
}, {});

export default sessionReducer;

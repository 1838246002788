import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName, withHandlers } from 'recompose';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

import Button from '../button';
import * as selectors from '../../store/selectors';
import { destroySession } from '../../store/actions';
import { qaSelector, isMobileGatewayApp } from '../../utils';

const UserName = styled('span')`
  margin: ${themeGet('spacing.2')}px ${themeGet('spacing.2')}px 0 ${themeGet('spacing.2')}px;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    font-size: ${themeGet('fonts.size.2')}px;
  }
`;

export default compose(
  connect(selectors.currentUserSelector, { destroySession }),
  withHandlers({
    signOutHandler: props => () => props.destroySession()
  }),
  setPropTypes({
    currentUser: PropTypes.object,
    signOutHandler: PropTypes.func.isRequired,
    destroySession: PropTypes.func.isRequired
  }),
  setDisplayName('SignOutButtons')
)(({ currentUser, signOutHandler }) => (
  <React.Fragment>
    {!isMobileGatewayApp &&
      <UserName className="c-user-name" data-qa={qaSelector('user-name')}> {currentUser.name || currentUser.email}</UserName>
    }
    <Button className="c-sign-out-button" onClick={signOutHandler} data-qa={qaSelector('sign-out-button')} variant={['inverse', 'session']}>
      Sign Out
    </Button>
  </React.Fragment>
));

import { createReducer } from 'redux-act';
import { fetchingHubs, hubsLoadedSuccessfully, hubsLoadingFail } from './actions';

const defaultState = {
  companies: [],
  loadingCompleted: false
};

const hubsReducer = createReducer({
  [fetchingHubs]: state => ({ ...state, loadingCompleted: false }),
  [hubsLoadedSuccessfully]: (state, payload) => ({ ...state, ...payload, loadingCompleted: true }),
  [hubsLoadingFail]: state => ({ ...state, loadingCompleted: false })
}, defaultState);

export default hubsReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';

import Button from '../button';
import { qaSelector } from '../../utils';

const Separator = styled('div')`
  display: inline-block;

  @media only screen and (max-width: ${themeGet('sizes.phone')}px) {
    height: 14px;
    border-left: 1px solid ${themeGet('colors.white')};
    vertical-align: sub;
  }
`;

export default compose(
  setPropTypes({ href: PropTypes.string }),
  setDisplayName('SignUpButton')
)(({ href }) => (
  <React.Fragment>
    <Separator/>
    <Button className="c-sign-up-button" href={href} data-qa={qaSelector('sign-up-button')} variant={['inverse', 'session']} title="Sign Up">
      Sign Up
    </Button>
  </React.Fragment>
));

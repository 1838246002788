import { createReducer } from 'redux-act';
import { fetchingFamilyBranding, familyBrandingLoadedSuccessfully, familyBrandingLoadingFail } from './actions';

const defaultState = { data: null, loadingCompleted: false };

const familyBrandingReducer = createReducer({
  [fetchingFamilyBranding]: state => ({ ...state, loadingCompleted: false }),
  [familyBrandingLoadedSuccessfully]: (state, payload) => ({ ...state, ...payload, loadingCompleted: true }),
  [familyBrandingLoadingFail]: state => ({ ...state, loadingCompleted: false })
}, defaultState);

export default familyBrandingReducer;

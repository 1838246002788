import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setPropTypes, setDisplayName } from 'recompose';

import * as selectors from '../../store/selectors';

import HubList from '../hub-list/index';
import MobileHeader from '../mobile-header/index';
import Footer from '../footer/index';

export default compose(
  connect(
    state => ({
      ...selectors.currentUserSelector(state)
    })
  ),
  setPropTypes({
    currentUser: PropTypes.object
  }),
  setDisplayName('MobileAppHubList')
)(() => (
  <React.Fragment>
    <MobileHeader />
    <HubList />
    <Footer />
  </React.Fragment>
));

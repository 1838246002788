import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName, branch, lifecycle, renderNothing, withProps } from 'recompose';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import * as selectors from '../../store/selectors';

const HiddenForm = styled('form')`
  display: none;
`;

export default compose(
  connect(state => selectors.authenticationPayloadSelector(state)),
  withProps({
    formRef: React.createRef()
  }),
  setPropTypes({
    url: PropTypes.string,
    payload: PropTypes.string,
    loadingCompleted: PropTypes.bool.isRequired,
    formRef: PropTypes.object
  }),
  branch(
    ({ loadingCompleted }) => !loadingCompleted,
    renderNothing
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.formRef.current) {
        this.props.formRef.current.submit();
      }
    }
  }),
  setDisplayName('HubAuthenticationForm')
)(({ url, payload, formRef }) => (
  <HiddenForm action={url} ref={formRef} method="POST">
    <input type="hidden" value={payload} name="SAMLResponse"/>
  </HiddenForm>
));

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, setDisplayName } from 'recompose';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Icon } from 'infl-icons';
import { themeGet } from 'styled-system';

const ValidationMessageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width 100%;
`;

const ValidIcon = styled('div')`
  font-size: 10px;
  padding-left: ${themeGet('spacing.3')}px;
`;

const ValidationText = styled('div')`
  padding-left: 10px;
  font-size: 12px;
`;

const valid = ({ theme }) => css`
  color: ${theme.colors.success[0]};
`;

const ValidationMessage = styled(({ children, ...props }) => (
  <ValidationMessageContainer className="c-validation-message-container" {...props}>
    <ValidIcon className="c-validation-icon">
      <Icon className="c-icon" icon="check-circle-o"/>
    </ValidIcon>
    <ValidationText className="c-validation-text">
      {children}
    </ValidationText>
  </ValidationMessageContainer>
))`
  display; flex;
  ${props => (props.valid === true ? valid : '')}
`;

export default compose(
  setPropTypes({ valid: PropTypes.bool }),
  setDisplayName('PasswordValidation')
)(ValidationMessage);
